import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { IUser } from 'pages/salary-fund/types';
import { FC,useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from 'store';
import { EInputStyleVariant,Input } from 'ui-new/input';
import { Modal } from 'widgets/modal';
import { ModalButtons } from 'widgets/modal/buttons';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().min(2,({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min} символов`)
    .required(ValidationMessages.get(ValidationTypes.required)),
  surname: yup.string().min(2,({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min} символов`)
    .required(ValidationMessages.get(ValidationTypes.required)),
  third_name: yup.string().min(2,({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min} символов`)
    .required(ValidationMessages.get(ValidationTypes.required)).nullable(),
  date_of_born: yup.date().typeError(ValidationMessages.get(ValidationTypes.required) ?? '').max(new Date(),'Дата рождения не может быть больше текущего дня')
    .required(ValidationMessages.get(ValidationTypes.required)),
  inn: yup.string().length(12,'ИНН должен содержать 12 цифр').required(ValidationMessages.get(ValidationTypes.required)),
});

interface FormValues {
  name: string;
  surname: string;
  third_name: string | null;
  date_of_born: Date;
  inn: string;
}

interface EmployeeInfoModalProps {
  user: IUser | null;
  onClose: () => void;
}

const EmployeeInfoModalComponent: FC<EmployeeInfoModalProps> = ({ user,onClose }) => {
  const { RootStore: { RoleStore: { updateEmployeeInfoConfirm } } } = useStore();

  const { handleSubmit,control } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: user ? {
      date_of_born: (user.date_of_born ?? '' as any),
      inn: user.inn,
      name: user.name,
      surname: user.surname,
      third_name: user.third_name,
    } : {}
  });

  const onSubmit = useCallback((values: FormValues) => {
    if (!user) return;
    updateEmployeeInfoConfirm({ ...values,date_of_born: dayjs(values.date_of_born).format('YYYY-MM-DD'),id: user.id },onClose);
  },[onClose,updateEmployeeInfoConfirm,user]);

  return (<Modal
    title='Карточка работника'
    maxWidth='480px'
    minWidth='480px'
    onClose={onClose}
  >
    <div style={{ display: 'flex',flexDirection: 'column' }}>
      <Input variant={EInputStyleVariant.basicInput} control={control} name='name' label='Имя'
        placeholder='Введите имя работника' type='text' />
      <Input variant={EInputStyleVariant.basicInput} control={control} name='surname' label='Фамилия'
        placeholder='Введите фамилию работника' type='text' />
      <Input variant={EInputStyleVariant.basicInput} control={control} name='third_name' label='Отчество (если имеется)'
        placeholder='Введите отчество работника' type='text' />
      <Input variant={EInputStyleVariant.basicInput} control={control} name='date_of_born' label='Дата рождения'
        type='date' />
      <Input variant={EInputStyleVariant.basicInput} control={control} name='inn' label='ИНН' placeholder='Введите ИНН (12 цифр)' type='text' />
      <div style={{ marginTop: '10px',alignSelf: 'flex-end' }}>
        <ModalButtons onPrimaryButtonClick={handleSubmit(onSubmit)} primaryButtonLabel={'Сохранить'} onSecondaryButtonClick={onClose} secondaryButtonLabel='Отменить' />
      </div>
    </div>
  </Modal>);
};

export const EmployeeInfoModal = observer(EmployeeInfoModalComponent);