import React,{ useEffect,useState } from 'react';
import cls from "./styles.module.scss";
import { EmployeesTable } from "./components/employee-table/employeesTable";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { getEmployees } from 'api/accessRights';

export const AccessRights = observer(() => {

  const { RootStore } = useStore();
  const { RoleStore } = RootStore;
  const { users,setUsers } = RoleStore;

  const [isLoading,setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getEmployees().then(res => {
      if (!res) throw new Error('Произошла ошибка');
      setUsers(res);
      setIsLoading(false);
    }).catch();
  },[RoleStore,setUsers]);

  return (
    <div className={cls.accessRightsPage}>
      <h2>Команда</h2>
      <EmployeesTable isLoading={isLoading} users={users} />
    </div>
  );
});
