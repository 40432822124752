import { observer } from 'mobx-react-lite';
import cls from '../styles.module.scss';
import { useEffect,useMemo } from 'react';
import { addSpaces } from 'assets/utils';
import { EmployeesTable } from './employees-table';
import { useStore } from 'store';

const BonusComponent = () => {
  const { RootStore: { SalaryFundStore: { tableBonusItems: { barista,helpers,joe },fetchGetBonuses,selectedDate,netOrGross } } } = useStore();
  const footerIsVisible = [helpers.length,barista.length,joe.length].filter(l => l > 0).length > 1;
  const sum = useMemo(() => {
    if (!footerIsVisible) return [0,0,0,0];

    let planSum = 0;
    let factSum = 0;
    let approveSum = 0;
    let paidSum = 0;
    barista.forEach((val) => {
      planSum += val[`plan_${netOrGross}`] ?? 0;
      factSum += val[`fact_${netOrGross}`] ?? 0;
      approveSum += val[`approved_${netOrGross}`] ?? 0;
      paidSum += val[`paid_${netOrGross}`] ?? 0;
    });
    helpers.forEach((val) => {
      planSum += val[`plan_${netOrGross}`] ?? 0;
      factSum += val[`fact_${netOrGross}`] ?? 0;
      approveSum += val[`approved_${netOrGross}`] ?? 0;
      paidSum += val[`paid_${netOrGross}`] ?? 0;
    });
    joe.forEach((val) => {
      planSum += val[`plan_${netOrGross}`] ?? 0;
      factSum += val[`fact_${netOrGross}`] ?? 0;
      approveSum += val[`approved_${netOrGross}`] ?? 0;
      paidSum += val[`paid_${netOrGross}`] ?? 0;
    });

    planSum = Math.floor(planSum * 100) / 100;
    factSum = Math.floor(factSum * 100) / 100;
    approveSum = Math.floor(approveSum * 100) / 100;
    paidSum = Math.floor(paidSum * 100) / 100;
    return [planSum,factSum,approveSum,paidSum];
  },[barista,footerIsVisible,helpers,joe,netOrGross]);
  const sumData = useMemo(() => sum.map(s => <td className={cls['td']}>{addSpaces(s)}</td>),[sum]);

  useEffect(() => {
    fetchGetBonuses();
  },[fetchGetBonuses,selectedDate]);

  return (<div>
    {barista.length > 0 && <EmployeesTable title='Работники бара' className={cls['table-rounding__top']} bonusList={barista} />}
    {helpers.length > 0 && <EmployeesTable title='Хелперы' className={barista.length === 0 ? cls['table-rounding__top'] : ''} bonusList={helpers} />}
    {joe.length > 0 && <EmployeesTable title='Работники кухни'
      className={(barista.length === 0 && helpers.length === 0) ? cls['table-rounding__top'] : ''} bonusList={joe} />}
    {footerIsVisible && <table style={{ position: 'sticky',bottom: '0' }} className={`${cls['table']} ${cls['table-rounding__bottom']}`}>
      <colgroup>
        <col width={44} />
        <col width={207} />
        <col width={181} />
        <col width={177} />
        <col width={175} />
        <col width={177} />
        <col width={175} />
      </colgroup>
      <tbody className={`${cls['tfoot']} ${cls['total-tfoot']} ${cls['rounding']}`}>
        <tr className={cls['tr']}>
          <td className={cls['td']} colSpan={3}>Всего</td>
          {sumData}
        </tr>
      </tbody>
    </table>}
  </div>);
};

export const BonusInformation = observer(BonusComponent);